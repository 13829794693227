<template>
  <b-form @submit.prevent="onSubmit" v-if="order" class="order-form">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" lg="8">
          <b-card>
            <h5>{{ $t("payment.cardPayment") }}</h5>
            <div class="custom-table">
              <table-lite
                :is-slot-mode="true"
                :columns="columnsPayments"
                :rows="order.payments"
                :sortable="{}"
              >
                <template v-slot:status="data">
                  <b-badge :variant="paymentStatusClass(data.value.status)">{{ data.value.status }}</b-badge>
                </template>
                <template v-slot:transactionId="data">
                  {{ data.value.transactionId }}
                </template>
                <template v-slot:gateway="data">
                  {{ data.value.gateway.replace("_", " ") }}
                </template>
                <template v-slot:price="data">
                  {{ $helper.priceFormat(data.value.price) }}
                </template>
                <template v-slot:paymentAttempt="data">
                  {{ $helper.formatDate(data.value.paymentAttempt) }}
                  {{ $helper.formatTime(data.value.paymentAttempt) }}
                </template>
              </table-lite>
            </div>
          </b-card>
          <b-card>
            <h5>{{ $t("navigations.products") }}</h5>
            <b-row v-if="enableEdit">
              <b-col cols="12">
                <b-form-group>
                  <label for="">{{ $t("product.singular") }}</label>
                  <v-select v-model="selectedProduct" :options="products"/>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group v-if="variants && variants.length">
                  <label for="">{{ $t("variant.singular") }}</label>
                  <v-select v-model="selectedVariant" :options="variants"/>
                </b-form-group>
              </b-col>
              <b-col cols="12" v-if="configurations && configurations.length">
                <b-form-group>
                  <label for="">{{ $t("variant.configurations") }}</label>
                  <v-select
                    multiple
                    v-model="selectedVariantConfigurations"
                    :options="configurations"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              class="add-product-btn"
              :disabled="!selectedVariant"
              @click="addProductToOrder"
              variant="secondary"
            >{{ $t("product.add") }}
            </b-button
            >

            <b-alert
              class="mt-3"
              variant="danger"
              :show="v$.orderProducts.$errors.length"
            >{{ $t("order.minProductWarning") }}
            </b-alert
            >

            <div
              v-if="orderProducts && orderProducts.length"
              class="custom-table"
            >
              <table-lite
                :is-slot-mode="true"
                :columns="columns"
                :rows="orderProducts"
                :sortable="{}"
              >
                <template v-slot:name="data">
                  {{ data.value.name }}
                  <div
                    v-if="
                      orderProductConfigurationsToView(data.value.productConfigurations) &&
                      orderProductConfigurationsToView(data.value.productConfigurations).length
                    "
                  >
                    <small
                      class="small"
                      v-for="(configuration, index) of orderProductConfigurationsToView(data.value.productConfigurations)"
                      :key="index"
                    >
                      {{
                        `${configuration.name} (+${$helper.priceFormat(
                          configuration.price,
                          2
                        )})`
                      }}
                    </small>
                  </div>
                  <div
                    v-if="data.value.extendedVoucher"
                  >
                    <small>
                      Poukaz: {{ data.value.extendedVoucherCode }}
                    </small>
                  </div>
                </template>
                <template v-slot:quantity="data">
                  <b-form-input
                    v-if="enableEdit"
                    type="number"
                    min="1"
                    step="1"
                    v-model="data.value.quantity"
                  />
                  <span v-else>{{ data.value.quantity }}</span>
                </template>
                <template v-slot:price="data">
                  {{ $helper.priceFormat(data.value.price) }}
                </template>
                <template v-slot:actions="data">
                  <div class="just-buttons">
                    <b-button variant="secondary" @click="removeOrderProduct(data.value.id)">
                      <bootstrap-icon
                        icon="trash"
                        size="1x"
                      />
                    </b-button>
                  </div>
                </template>
              </table-lite>
            </div>

            <div v-if="order.couponCode" class="order-discount">
              <div>{{ $t('coupon.discount') }} - {{ order.couponCode }}</div>
              <div>-{{ $helper.priceFormat(order.discount) }}</div>
            </div>

            <div
              v-if="orderProducts && orderProducts.length"
              class="order-total"
            >
              <div>{{ $t("order.total") }}</div>
              <div>{{ $helper.priceFormat(orderTotal, 2) }}</div>
            </div>
          </b-card>

          <b-card>
            <b-row>
              <b-col cols="12" sm="6">
                <h5>{{ $t("user.contact") }}</h5>
              </b-col>
              <b-col cols="12" sm="6"></b-col>

              <b-col cols="12" sm="6">
                <b-form-group
                  :label="$t('user.givenName')"
                  :class="{ error: v$.order.client.givenName.$errors.length }"
                >
                  <b-form-input v-model="order.client.givenName"/>
                  <div
                    class="input-errors"
                    v-for="error of v$.order.client.givenName.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group
                  :label="$t('user.familyName')"
                  :class="{ error: v$.order.client.familyName.$errors.length }"
                >
                  <b-form-input v-model="order.client.familyName"/>
                  <div
                    class="input-errors"
                    v-for="error of v$.order.client.familyName.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group
                  :label="$t('user.email')"
                  :class="{ error: v$.order.client.email.$errors.length }"
                >
                  <b-form-input v-model="order.client.email" @keydown.space.prevent />
                  <div
                    class="input-errors"
                    v-for="error of v$.order.client.email.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('user.telephone')">
                  <b-form-input v-model="order.client.telephone"/>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('address.companyName')">
                  <b-form-input v-model="order.client.companyName"/>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('address.ico')">
                  <b-form-input v-model="order.client.ico"/>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('address.dic')">
                  <b-form-input v-model="order.client.dic"/>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6">
                <b-form-group :label="$t('address.icdph')">
                  <b-form-input v-model="order.client.icdph"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card>
            <b-row>
              <b-col cols="12" sm="6">
                <h5>{{ $t("address.shipping") }}</h5>
                <b-form-group :label="$t('address.streetAddress')">
                  <b-form-input v-model="order.shippingAddress.streetAddress"/>
                </b-form-group>
                <b-form-group :label="$t('address.city')">
                  <b-form-input v-model="order.shippingAddress.city"/>
                </b-form-group>
                <b-form-group :label="$t('address.postalCode')">
                  <b-form-input v-model="order.shippingAddress.postalCode"/>
                </b-form-group>
                <b-form-group :label="$t('address.country')">
                  <b-form-input v-model="order.shippingAddress.country"/>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="6">
                <h5>{{ $t("address.billing") }}</h5>
                <b-form-group :label="$t('address.streetAddress')">
                  <b-form-input v-model="order.billingAddress.streetAddress"/>
                </b-form-group>
                <b-form-group :label="$t('address.city')">
                  <b-form-input v-model="order.billingAddress.city"/>
                </b-form-group>
                <b-form-group :label="$t('address.postalCode')">
                  <b-form-input v-model="order.billingAddress.postalCode"/>
                </b-form-group>
                <b-form-group :label="$t('address.country')">
                  <b-form-input v-model="order.billingAddress.country"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="12" lg="4">
          <b-card>
            <b-form-group
              v-if="type === 'update'"
              :label="$t('order.orderId')"
              label-for="h-id"
            >
              <b-form-input v-model="order.id" id="h-id" disabled/>
            </b-form-group>

            <b-form-group
              v-if="type === 'update'"
              :label="$t('order.orderHash')"
              label-for="h-hash"
            >
              <b-form-input v-model="order.hash" id="h-hash" disabled/>
            </b-form-group>


            <b-form-group
              v-if="type === 'update'"
              :label="$t('order.createdAt')"
              label-for="h-hash"
            >
              <datepicker
                v-model="order.createdAt"
                :enableTimePicker="true"
                class="form-input"
                format="dd. MM. yyyy HH:mm:ss"
                previewFormat="dd. MM. yyyy HH:mm:ss"
                disabled
              ></datepicker>
            </b-form-group>

            <b-form-group
              :class="{ error: v$.order.statusSelect.$errors.length }"
              :label="$t('order.status')"
              label-for="h-status"
            >
              <v-select
                id="h-status"
                v-model="order.statusSelect"
                :options="statuses"
              />
              <div
                class="input-errors"
                v-for="error of v$.order.statusSelect.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              v-if="order.statusSelect && order.statusSelect.id === 'CANCELLED'"
              :label="$t('order.cancelReason')"
              label-for="h-cancelReason"
            >
              <v-select v-model="order.cancelSelect" :options="cancelReasons" />
            </b-form-group>

            <b-form-group
              v-if="order.statusSelect && order.statusSelect.id === 'CANCELLED'"
              :label="$t('order.cancelDescription')"
              label-for="h-cancelDescription"
            >
              <b-form-textarea v-model="order.cancelDescription" id="h-cancelDescription" />
            </b-form-group>

            <b-form-group :label="$t('order.user')" label-for="h-client">
              <v-select
                id="h-client"
                v-model="selectedClient"
                :options="users"
              />
            </b-form-group>

            <b-form-group
              :class="{ error: v$.order.paymentMethod.$errors.length }"
              :label="$t('order.paymentMethod')"
              label-for="h-paymentMethod"
            >
              <v-select
                id="h-paymentMethod"
                v-model="order.paymentMethod"
                :options="paymentMethods"
              />
              <div
                class="input-errors"
                v-for="error of v$.order.paymentMethod.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              :class="{ error: v$.order.financialStatus.$errors.length }"
              :label="$t('order.financialStatus')"
              label-for="h-financialStatus"
            >
              <v-select
                id="h-financialStatus"
                v-model="order.financialStatus"
                :options="financialStatusses"
              />
              <div
                class="input-errors"
                v-for="error of v$.order.financialStatus.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>

            <b-form-group
              :label="$t('order.shippingPrice')"
              label-for="h-shippingPrice"
            >
              <b-form-input type="number" step="0.01" min="0" v-model="order.shippingPrice"/>
            </b-form-group>

            <b-form-group
              :label="$t('order.postNumber')"
              label-for="h-postNumber"
            >
              <b-form-input v-model="order.postNumber"/>
            </b-form-group>

            <b-form-group
              :label="$t('order.description')"
              label-for="h-description"
            >
              <b-form-textarea v-model="order.description" id="h-description"/>
            </b-form-group>

            <b-form-group
              :label="$t('order.notes')"
              label-for="h-notes"
            >
              <b-form-textarea v-model="order.notes" id="h-notes"/>
            </b-form-group>

            <b-form-group v-if="enableEdit" :label="$t('voucher.create')">
              <b-form-checkbox v-model="createVoucher" switch/>
            </b-form-group>

            <div v-if="createVoucher">
              <b-form-group
                :label="$t('voucher.validityDate')"
                :class="{ error: v$.voucher.validUntil.$errors.length }"
              >
                <datepicker
                  v-model="voucher.validUntil"
                  :enableTimePicker="false"
                  class="form-input"
                  format="dd. MM. yyyy"
                  previewFormat="dd. MM. yyyy"
                ></datepicker>
                <div
                  class="input-errors"
                  v-for="error of v$.voucher.validUntil.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <b-form-group :label="$t('voucher.activationDate')">
                <datepicker
                  v-model="voucher.activationDate"
                  :enableTimePicker="false"
                  class="form-input"
                  format="dd. MM. yyyy"
                  previewFormat="dd. MM. yyyy"
                ></datepicker>
              </b-form-group>

              <b-form-group
                :label="$t('voucher.status')"
                :class="{ error: v$.voucher.status.$errors.length }"
              >
                <v-select v-model="voucher.status" :options="voucherStatuses"/>
                <div
                  class="input-errors"
                  v-for="error of v$.voucher.status.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('voucher.password')"
                :class="{ error: v$.voucher.pin.$errors.length }"
              >
                <b-form-input v-model="voucher.pin"/>
                <div
                  class="input-errors"
                  v-for="error of v$.voucher.pin.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('voucher.code')"
                :class="{ error: v$.voucher.code.$errors.length }"
              >
                <b-form-input v-model="voucher.code"/>
                <div
                  class="input-errors"
                  v-for="error of v$.voucher.code.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('voucher.originalPrice')"
                :class="{ error: v$.voucher.originalPrice.$errors.length }"
              >
                <b-form-input type="number" step="0.01" min="0" v-model="voucher.originalPrice"/>
                <div
                  class="input-errors"
                  v-for="error of v$.voucher.originalPrice.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <b-form-group
                :label="$t('voucher.purchasePrice')"
                :class="{ error: v$.voucher.purchasePrice.$errors.length }"
              >
                <b-form-input type="number" step="0.01" min="0" v-model="voucher.purchasePrice"/>
                <div
                  class="input-errors"
                  v-for="error of v$.voucher.purchasePrice.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-group>

              <div v-for="(p, ix) of voucher.passengers" :key="ix">
                <hr/>

                <div class="row">
                  <div class="col-sm-6">
                    <b-form-group
                      :label="$t('user.givenName')"
                      :class="{
                        error:
                          v$.voucher.passengers.$each.$response.$errors[ix]
                            .givenName.length,
                      }"
                    >
                      <b-form-input v-model="p.givenName"/>
                      <div
                        class="input-errors"
                        v-for="error of v$.voucher.passengers.$each.$response
                          .$errors[ix].givenName"
                        :key="error.$uid"
                      >
                        <small class="text-danger">{{ error.$message }}</small>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6">
                    <b-form-group
                      :label="$t('user.familyName')"
                      :class="{
                        error:
                          v$.voucher.passengers.$each.$response.$errors[ix]
                            .familyName.length,
                      }"
                    >
                      <b-form-input v-model="p.familyName"/>
                      <div
                        class="input-errors"
                        v-for="error of v$.voucher.passengers.$each.$response
                          .$errors[ix].familyName"
                        :key="error.$uid"
                      >
                        <small class="text-danger">{{ error.$message }}</small>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6">
                    <b-form-group
                      :label="$t('user.email')"
                      :class="{
                        error:
                          v$.voucher.passengers.$each.$response.$errors[ix]
                            .email.length,
                      }"
                    >
                      <b-form-input v-model="p.email" @keydown.space.prevent />
                      <div
                        class="input-errors"
                        v-for="error of v$.voucher.passengers.$each.$response
                          .$errors[ix].email"
                        :key="error.$uid"
                      >
                        <small class="text-danger">{{ error.$message }}</small>
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6">
                    <b-form-group :label="$t('user.telephone')">
                      <b-form-input v-model="p.telephone"/>
                    </b-form-group>
                  </div>
                </div>

                <b-button class="mb-3" @click="removePassenger(ix)">{{
                    $t("order.removePassenger")
                  }}
                </b-button>
              </div>

              <b-button variant="primary" @click="addPassenger">{{
                  $t("order.addPassenger")
                }}
              </b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import vSelect from "vue-select";
import TableLite from "vue3-table-lite";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import moment from "moment";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  components: {
    vSelect,
    TableLite,
    Datepicker,
  },
  props: {
    orderObj: {
      type: Object,
      default() {
      },
    },
    action: {type: String, default: null},
    type: {type: String, default: "update"},
  },
  data() {
    return {
      order: this.orderObj,
      title: "",
      statuses: [],
      paymentMethods: [],
      financialStatusses: [],
      cancelReasons: [],
      users: [],
      products: [],
      variants: [],
      configurations: [],
      orderProducts: [],
      selectedProduct: null,
      selectedVariant: null,
      selectedVariantConfigurations: [],
      selectedClient: null,
      columns: [
        {
          label: this.$t("product.name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("variant.quantity"),
          field: "quantity",
          sortable: false,
          width: "120px",
        },
        {
          label: this.$t("variant.sellPrice"),
          field: "price",
          sortable: false,
        },
      ],
      columnsPayments: [
        {
          label: this.$t("payment.status"),
          field: "status",
          sortable: false,
        },
        {
          label: this.$t("payment.transactionId"),
          field: "transactionId",
          sortable: false,
        },
        {
          label: this.$t("payment.gateway"),
          field: "gateway",
          sortable: false,
        },
        {
          label: this.$t("payment.price"),
          field: "price",
          sortable: false,
        },
        {
          label: this.$t("payment.paymentAttempt"),
          field: "paymentAttempt",
          sortable: false,
        },
      ],

      // add voucher
      createVoucher: false,
      voucherStatuses: [],
      voucher: {
        validUntil: null,
        activationDate: null,
        pin: null,
        status: null,
        passengers: [],
        code: null,
      },
      passenger: {
        email: "",
        telephone: "",
        familyName: "",
        givenName: "",
      },
    };
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    const validation = {
      orderProducts: { required: this.$translateError('required') },
      order: {
        client: {
          email: { required: this.$translateError('required'), email: this.$translateError('email') },
          familyName: { required: this.$translateError('required') },
          givenName: { required: this.$translateError('required') },
        },
        statusSelect: { required: this.$translateError('required') },
        paymentMethod: { required: this.$translateError('required') },
        financialStatus: { required: this.$translateError('required') }
      },
    };

    if (this.createVoucher) {
      validation.voucher = {
        validUntil: { required: this.$translateError('required') },
        status: { required: this.$translateError('required') },
        pin: { required: this.$translateError('required') },
        code: { required: this.$translateError('required') },
        originalPrice: { required: this.$translateError('required') },
        purchasePrice: { required: this.$translateError('required') },
        passengers: {
          $each: helpers.forEach({
            email: { email: this.$translateError('email') },
            familyName: { required: this.$translateError('required') },
            givenName: { required: this.$translateError('required') },
          }),
        },
      };
    }
    return validation;
  },
  async created() {
    await this.getUserList({params: {role: "ROLE_CLIENT"}}, this.users);
    await this.getProductsList(this.products);
    await this.getEnumList(
      ["order_status", "order_payment_method", "voucher_status", "payment_status", "order_cancel_reason"],
      this.statuses,
      this.paymentMethods,
      this.voucherStatuses,
      this.financialStatusses,
      this.cancelReasons
    );
    this.statuses = this.statuses.filter((status) => status.id !== "DRAFT");

    if (this.order.items) {
      this.orderProducts = this.order.items;
    }

    if (this.order.user) {
      const existingUser = this.users.find((u) => u.id === this.order.user);
      this.order.user = existingUser;
    }

    if (this.order.paymentMethod) {
      this.order.paymentMethod = {
        id: this.order.paymentMethod,
        label: this.$helper.getEnumTranslation(
          "order_payment_method",
          this.order.paymentMethod,
          this.$i18n.locale
        ),
      };
    }

    if (this.order.financialStatus) {
      this.order.financialStatus = {
        id: this.order.financialStatus,
        label: this.$helper.getEnumTranslation(
          "payment_status",
          this.order.financialStatus,
          this.$i18n.locale
        ),
      };
    }

    if (this.enableEdit) {
      this.columns.push({
        label: this.$t("order.actions"),
        field: "actions",
        sortable: false,
        width: "120px",
      });
    }
  },
  watch: {
    action() {
      if (this.action) {
        this.onSubmit();
      }
    },
    orderObj() {
      this.order.price = this.orderObj.price;
      this.order.paymentProcessingFee = this.orderObj.paymentProcessingFee;
    },
    selectedProduct() {
      this.selectedVariant = null;
      this.variants = [];
      if (this.selectedProduct) {
        this.getProductVariants();
      }
    },
    selectedVariant() {
      this.configurations = [];
      this.selectedVariantConfigurations = [];
      if (
        this.selectedVariant &&
        this.selectedVariant.variantObject.productConfigurations
      ) {
        this.selectedVariant.variantObject.productConfigurations.forEach(
          (c) => {
            this.configurations.push({
              label: c.name,
              id: c["@id"],
              configurationObject: c,
            });
          }
        );
      }
    },
    selectedClient() {
      this.getClientData();
    },
  },
  computed: {
    user() {
      return this.order.user;
    },
    orderTotal() {
      if (this.order.id) {
        return this.order.totalPrice;
      } else {
        return this.$helper.getOrderTotal(this.orderProducts);
      }
    },
    enableEdit() {
      return this.order.id ? false : true;
    },
  },
  methods: {
    orderProductConfigurationsToView(configurations) {
      return configurations.filter(cf => cf.productConfiguration  !== `/product_configurations/${process.env.VUE_APP_EXTENDED_VOUCHER_CONFIG_ID}`)
    },
    paymentStatusClass(status) {
      let className = 'danger'
      if(status === 'PAID') {
        className = 'success'
      }
      if(status === 'PENDING') {
        className = 'warning'
      }
      return className
    },
    addProductToOrder() {
      const alreadyIn = this.orderProducts.find(
        (op) => op["@id"] === this.selectedVariant.variantObject["@id"]
      );
      if (alreadyIn) {
        alreadyIn.quantity = parseInt(alreadyIn.quantity) + 1;
      } else {
        this.selectedVariant.variantObject.quantity = 1;
        this.selectedVariant.variantObject.productConfigurations =
          this.selectedVariantConfigurations.map((c) => c.configurationObject);
        this.orderProducts.push(this.selectedVariant.variantObject);
      }

      if (!this.voucher.originalPrice && this.orderProducts.length === 1) {
        this.voucher.originalPrice = this.$helper.getOrderTotal(this.orderProducts);
        this.voucher.purchasePrice = this.orderProducts[0].purchasePrice
      }
    },
    removeOrderProduct(id) {
      const index = this.orderProducts.findIndex((op) => op.id === id);
      if (index >= 0) {
        this.orderProducts.splice(index, 1);
      }

      if (this.orderProducts.length === 0) {
        this.voucher.originalPrice = 0
        this.voucher.purchasePrice = 0
      }
    },
    updateOrder(id, body) {
      return this.update(
        this.$Orders,
        id,
        body,
        this.$t("order.updated"),
        null,
        this.success,
        this.error
      );
    },
    createOrder(body) {
      return this.create(
        this.$Orders,
        body,
        this.$t("order.created"),
        null,
        this.redirect,
        this.error
      );
    },
    getProductVariants() {
      this.$Products
        .getResourceByUrl({url: `${this.selectedProduct.id}/variants`, params: { pagination: false }})
        .then((response) => {
          if (response.status === 200) {
            response.data["hydra:member"].forEach((variant) => {
              this.variants.push({
                label: this.$helper.buildParamString(variant.customParameters),
                id: variant["@id"],
                variantObject: variant,
              });
            });
          }
        });
    },
    getClientData() {
      this.$Users
        .getResourceByUrl({url: this.selectedClient.id})
        .then((response) => {
          if (response.status === 200) {
            this.order.client = response.data;
          }
        });
    },
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          client: this.order.client,
          status: this.order.statusSelect.id,
          billingAddress: this.order.billingAddress,
          shippingAddress: this.order.shippingAddress,
          paymentMethod: this.order.paymentMethod.id,
          financialStatus: this.order.financialStatus.id,
          shippingPrice: this.order.shippingPrice ? this.order.shippingPrice.toString() : "0",
          postNumber: this.order.postNumber ? this.order.postNumber.toString() : null,
          description: this.order.description,
          notes: this.order.notes,
          cancelReason: '',
          cancelDescription: '',
          items: [],
        };

        if (body.status === 'CANCELLED') {
          if (this.order.cancelSelect) {
            body.cancelReason = this.order.cancelSelect.id
          }
          body.cancelDescription = this.order.cancelDescription
        }

        if (this.createVoucher) {
          body.voucher = {
            validUntil: moment(this.voucher.validUntil).format(),
            activationDate: this.voucher.activationDate ? moment(this.voucher.activationDate).format() : '',
            pin: this.voucher.pin,
            status: this.voucher.status.id,
            code: this.voucher.code.replace(/[^A-Za-z0-9]/g, ''),
            originalPrice: this.voucher.originalPrice ? this.voucher.originalPrice.toString() : '0',
            purchasePrice: this.voucher.purchasePrice ? this.voucher.purchasePrice.toString() : '0',
            passengers: JSON.parse(JSON.stringify(this.voucher.passengers)),
          };
        }

        body.items = this.orderProducts.map((product) => {
          const orderItem = {
            productVariantId: product.id,
            quantity: parseInt(product.quantity),
          };
          if (product.productConfigurations) {
            orderItem.productConfigurations = product.productConfigurations.map(
              (conf) => {
                const confItem = {
                  productConfigurationId: conf.id,
                  quantity: conf.quantityAble ? 1 : parseInt(product.quantity),
                };
                return confItem;
              }
            );
          }
          return orderItem;
        });

        if (this.action === "add") {
          this.createOrder(this.$helper.cleanPayload(body));
        }
        if (this.action === "update") {
          const id = this.$route.params.id;
          body.status = this.order.statusSelect.id;
          this.updateOrder(id, this.$helper.cleanPayload(body));
        }
        this.$emit("clearAction");
      } else {
        this.$emit("clearAction");
      }
    },
    redirect() {
      this.$router.push(`/orders`);
    },
    success() {
      this.$emit("clearAction");
    },
    error() {
      this.$emit("clearAction");
    },
    addPassenger() {
      this.voucher.passengers.push(JSON.parse(JSON.stringify(this.passenger)));
    },
    removePassenger(index) {
      this.voucher.passengers.splice(index, 1);
    }
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
