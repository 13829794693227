<template>
  <div v-if="order">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('order.create')"
      :title="$t('order.create')"
      @submitPressed="submitPressed"
    />
    <order-form :order-obj="order" :action="action" type="add" @clearAction="clearAction" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import OrderForm from '@/views/Admin/Orders/components/OrderForm.vue';

export default {
  components: {
    TitleBar,
    OrderForm
  },
  data() {
    return {
      action: null,
      order: {
        statusSelect: {},
        shippingAddress: {},
        billingAddress: {},
        client: {},
        items: [],
        description: '',
        notes: '',
        tags: []
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    },
    clearAction() {
      if(this.action) {
        this.action = null;
      }
    },
  }
};
</script>
